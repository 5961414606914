<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="满意度调查系统" describe="患者满意度是评价医院治疗结果和医疗服务质量的指标之一，也是医院开展“以患者为中心”改善服务质量的客观依据。患者满意度系统建立全院满意度调查体系，支持通过短信、微信、电话、平板电脑、二维码等多种调查方式开展调查工作，同时支持人工智能技术模拟真人对话场景，覆盖院内外所有满意度调查场景。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/satisfaction1.png" class="img1" alt="">
        <img src="@/assets/product/satisfaction2.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1300px;
    margin: 0 auto;
    .img1 {
      width: 100%;
      height: 570px;
      margin-bottom: 60px;
    }
    .img2 {
      width: 100%;
      height: 930px;
    }
  }
}
</style>
